@media screen and (min-width: 2560px) {
	html {
		height: 100vh;
		background-color: black;
	}
}

html {
	background-color: black;
	height: 100%;
}
body {
	margin: 0;
	height: inherit;
}

#root {
	height: inherit;
}
